<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Codes pays</h2>
                <table class="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Code</th>
                            <th scope="col">Pays</th>
                            <th scope="col">Code 3 car.</th>
                            <th scope="col">Code int.</th>      
                            <th scope="col">Code Insee</th>                                                      
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="( mapay, index ) of mapays" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ mapay?.code }}</td>
                            <td>{{ mapay?.lib }}</td>      
                            <td>{{ mapay?.code3 }}</td>  
                            <td>{{ mapay?.codeint }}</td>         
                            <td>{{ mapay?.insee }}</td>                                                                                                       
                         </tr>
                    </tbody>
                </table>
            </div>
        <!-- </div>
    </div> -->
</div>
</template>
<script>
export default {
    name: 'ListMapays',
    data() {
        return {
            mapays: []
        }
    },
    mounted() {
        this.getMapays()
    },
    methods: {
        async getMapays() {
            try {
                const { data } = await this.$http.get('/mapays');
                this.mapays = data?.items;
            } catch (e) {
                console.error(e)
            }
        },
    }

}
</script>
<style lang="">

</style>