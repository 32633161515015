<template lang="">

<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Ajouter une adresse </h2>
                <form @submit.prevent="addAdresse()">
                    <div class="row mt-3" >
                        <div class="col-2">
                        <label for="adr_num" class="d-flex align-items-left">Numéro</label>
                        <input type="text" id="adr_num" name="adr_num" class="form-control" v-model="adresse.adr_num" />    
                        </div>
                        <div class="col-3">
                        <label for="adr_bt" class="d-flex align-items-left">B/T</label>
                        <input type="text" id="adr_bt" name="adr_bt" class="form-control" v-model="adresse.adr_bt" /> 
                        </div>
                        <div class="col">                                       
                        <label for="adr_rue" class="d-flex align-items-left">Rue</label>
                        <input type="text" id="adr_rue" name="adr_rue" class="form-control" v-model="adresse.adr_rue" /> 
                        </div>                  
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label for="adr_comp" class="d-flex align-items-left">Complément adresse</label>
                            <input type="text" id="adr_comp" name="adr_comp" class="form-control" v-model="adresse.adr_comp" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <!-- <div class="col-2">
                            <label for="adr_cpos" class="d-flex align-items-left">Code postal</label>
                            <select v-model="adresse.adr_cpos" class="form-control">
                            <option v-for="macpo in macpos" :value="macpo?.code">
                                {{ macpo?.code }} {{ macpo?.ville }}
                            </option>
                            </select> 
                        </div> -->
                        <div class="col">                                       
                            <label for="adr_cpos" class="d-flex align-items-left">Code postal</label> 
                            <input maxlength="5" type=number  id="adr_cpos" name="adr_cpos" class="form-control" v-model="adresse.adr_cpos" />
                        </div>                        
                        <div class="col">                                       
                            <label for="adr_ville" class="d-flex align-items-left">Ville</label> 
                            <input type="text" id="adr_ville" name="adr_ville" class="form-control" v-model="adresse.adr_ville"/>
                        </div>
                     </div>                                
                    <div class="row mt-3">
                        <div class="col">
                            <label for="adr_pays" class="d-flex align-items-left">Pays</label>
                            <input type="text" id="adr_pays" name="adr_pays" class="form-control" v-model="adresse.adr_pays"  />
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/paie/parametres/cladresse')" >Annuler</button>                                    
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'AddAdresse',

    data() {
        return {
            cladresses: [],
            adresse: {
                adr_num: null,
                adr_bt: null,
                adr_rue: null,
                adr_comp: null,
                adr_cpos: null,
                adr_ville: null,
                adr_pays: 'Maroc'                
            },
            total: null,
            filters: {
                client: null
            },
            // macpos: [],
            // selected: null,
        }              
    },

    computed: {
        ...mapGetters(['currentUser'])
    },
//    mounted() {
//        this.getMacpos()
//    },
    methods: {
        async addAdresse() {
            try {
                this.filters.client = this.currentUser.client;   
                const { data } = await this.$http.get('/cladresses',
                {
                     params: this.filters
                });
                this.cladresses = data?.items;
                var btCodenum = "000"+( Number(this.cladresses.length) +1).toString();
                this.adresse.client = this.currentUser.client;   
                this.adresse.code = btCodenum.substr(btCodenum.length-3);

            } catch (e) {
                console.error(e)
            }
            try {
                await this.$http.post('/cladresses', this.adresse);
                this.$router.push('/paie/parametres/cladresse')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },
        // async getMacpos() {
        //     try {
        //         const { data } = await this.$http.get('/macpos');
        //         this.macpos = data?.items;

        //     } catch (e) {
        //         console.error(e)
        //     }
        // },        

    }
}
</script>
<style lang="">

</style>