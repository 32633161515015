<template>
    <div class="row mt-5">
        <div class="col-md-5 m-auto">
            <div class="card card-header" style="background-color: white;">
                <h3>Vérification de l'Email</h3>
            </div>   
            <div class="card card-body text-center">
                <small>Saisissez le code reçu lors de votre inscription</small>
                <form @submit.prevent="verify()">
                    <div class="form-group">
                        <label for="token" class="float-left">Code</label>
                        <input required type="text" id="token" name="token" class="form-control" v-model="token"/>
                    </div>
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Envoyer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/')" >Annuler</button>  

                </form>
                <p class="mt-4">
                    <small><span class="float-right">Email déjà vérifié ? <a href="/auth/login"> Connexion </a></span>
                    <span class="float-left">Vous n'avez pas reçu de code ? <a href="javascript:void(0)"> Ré-envoyer</a></span></small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'EmailVerification',
    data() {
        return {
            token: null,
            email: null,
        };
    },
    mounted() {
        this.email = this.$route.query.email;
    },
    methods: {
        async verify() {
            try {
                await axios.post("https://paiemaroc.osc-fr1.scalingo.io/auth/email_verification", {
                    email: this.email,
                    token: this.token
                });
                Swal.fire({
                    icon: 'success',
                    title: 'Vérification effectuée avec succès !',
                    text: 'Vous pouvez dès à présent vous connecter à votre compte.',
                });
                this.$router.push('/maroc/auth/login');
            } catch (e) {
                console.log(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },
    },
}
</script>