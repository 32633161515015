<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Utilisateurs</h2>
                <div><button @click="this.$router.push({ path: '/maroc//admin/parametres/adduser'})"  class="w-25 btn btn-primary">Ajouter un utilisateur</button></div>                
                <table class="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Email</th>
                            <th scope="col">Nom</th>
                            <th scope="col">Prénom</th>
                            <th scope="col">Fonction</th>                            
                            <th scope="col">Code client</th>    
                            <th scope="col">Rôle</th>
                            <!-- <th scope="col">Date création</th>    -->
                            <th scope="col">Email vérification</th>                                                                                    
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="( aduser, index ) of adusers" :key="index">
                            <!-- <th scope="row">{{ index + 1 }}</th> -->
                            <th scope="row">{{ aduser?.id }}</th>                            
                            <td class="text-left">{{ aduser?.email }}</td>
                            <td>{{ aduser?.nom }}</td> 
                            <td>{{ aduser?.prenom }}</td>        
                            <td>{{ aduser?.fonction }}</td>                                                  
                            <td>{{ aduser?.client }}</td>
                            <td>{{ aduser?.role }}</td> 
                            <td>{{ aduser?.email_verification }}</td>                             
                            <!-- <td>{{ aduser?.created_at.substr(0,10)}}</td>  -->
                            <td>
                                <router-link  :to="`/maroc/admin/parametres/updateuser/${aduser.id}`" class="btn btn-outline-secondary me-2"> 
                                    <svg  width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                    </svg>
                                </router-link>
                                <button type="button" class="btn btn-outline-secondary" @click="deleteUser(aduser.id)" >
                                    <svg width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                    </svg>
                                </button>  
                            </td>   
                         </tr>
                    </tbody>
                </table>
            </div>
        <!-- </div>
    </div> -->
</div>
</template>
<script>
export default {
    name: 'ListAdusers',
    data() {
        return {
            adusers: [],
            userid: null,
            filters: {
                id: null,         
            },   
       
        }
    },
    mounted() {
        this.getAdusers()
    },
    methods: {
        async getAdusers() {
            try {
                const { data } = await this.$http.get('/adusers');
                this.adusers = data?.items;
            } catch (e) {
                console.error(e)
            }
        },

        async deleteUser(userid) {
            this.filters.id = userid; 
            try {

                await this.$http.delete('/adusers',
                {
                     params: this.filters
                }
                );
                this.$router.go();                
                this.$router.push({ path: '/maroc/admin/parametres/adusers', replace: true })     
             } catch (e) {
                console.error(e)
            }                              
        },    
    }

}
</script>
<style lang="">

</style>