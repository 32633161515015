<template lang="">

<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Ajouter une banque </h2>
                <form @submit.prevent="addBanque()">
                    <div class="row mt-3" >
                        <div class="col">
                        <label for="titulaire" class="d-flex align-items-left">Titulaire</label>
                        <input type="text" id="titulaire" name="titulaire" class="form-control" v-model="banque.titulaire" maxlength="50" />    
                        </div>
                        <div class="col">
                        <label for="banque" class="d-flex align-items-left">Banque</label>
                        <input type="text" id="banque" name="banque" class="form-control" v-model="banque.banque" maxlength="50"/>    
                        </div>                        
               
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                        <label for="domiciliation" class="d-flex align-items-left">Domiciliation</label>
                        <input type="text" id="domiciliation" name="domiciliation" class="form-control" v-model="banque.domiciliation" maxlength="50"/>    
                        </div>  
                        <div class="col">                                       
                            <label for="code_banque" class="d-flex align-items-left">Code Banque</label> 
                            <input type="text" id="code_banque" name="code_banque" class="form-control" v-model="banque.code_banque" maxlength="3"/>
                        </div>                         
                    </div>
                    <div class="row mt-3">
                   
                        <div class="col">                                       
                            <label for="code_ville" class="d-flex align-items-left">Code ville</label> 
                            <input type="text" id="code_ville" name="code_ville" class="form-control" v-model="banque.code_ville" maxlength="3"/>
                        </div> 
                        <div class="col">                                       
                            <label for="code_compte" class="d-flex align-items-left">Code compte</label> 
                            <input type="text" id="code_compte" name="code_compte" class="form-control" v-model="banque.code_compte" maxlength="16" />
                        </div> 
                        <div class="col">                                       
                            <label for="code_rib" class="d-flex align-items-left">RIB</label> 
                            <input type="text" id="code_rib" name="code_rib" class="form-control" v-model="banque.code_rib" maxlength="2" />
                        </div>                        
                     </div>                                
                    <div class="row mt-3" >

                        <div class="col">
                        <label for="swift" class="d-flex align-items-left">Swift</label>
                        <input type="text" id="swift" name="swift" class="form-control" v-model="banque.code_swift" maxlength="8" />    
                        </div>
                        <div class="col">
                        <label for="iban" class="d-flex align-items-left">Iban</label>
                        <input type="text" id="iban" name="iban" class="form-control" v-model="banque.code_iban" maxlength="4" />    
                        </div>                        
               
                    </div>
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/paie/parametres/clbanque')" >Annuler</button>                                    
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'AddBanque',

    data() {
        return {
            clbanques: [],
            banque: {
                titulaire: null,
                banque: null,
                domiciliation: null,
                code_banque: null,
                code_ville: null,
                code_compte: null,
                code_rib: null,                
                code_swift:null,
                code_iban:null,            

            },
            total: null,
            filters: {
                client: null
            },

        }              
    },

    computed: {
        ...mapGetters(['currentUser'])
    },

    methods: {
        async addBanque() {
            try {
                this.filters.client = this.currentUser.client;   
                const { data } = await this.$http.get('/clbanques',
                {
                     params: this.filters
                });
                this.clbanques = data?.items;
                var btCodenum = "000"+( Number(this.clbanques.length) +1).toString();
                this.banque.client = this.currentUser.client;   
                this.banque.code = btCodenum.substr(btCodenum.length-3);

            } catch (e) {
                console.error(e)
            }
            try {
                await this.$http.post('/clbanques', this.banque);
                this.$router.push('/paie/parametres/clbanque')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },
     

    }
}
</script>
<style lang="">

</style>