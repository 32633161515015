<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-8 m-auto"> -->
            <div class="card card-body text-center">
                <h3>Inscription</h3>
            </div>
            <div class="card card-body text-center">
                <form @submit.prevent="register()">

                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="email" class="float-left">Email</label>
                        <input type="email" id="email" name="email" class="form-control" v-model="user.email" />
                        </div>                 
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="email" class="float-left">Mot de passe</label>
                        <input type="password" id="password" name="password" class="form-control" v-model="user.password" />
                        </div>       
                        <div class="col-5">
                        <label for="email" class="float-left">Confirmez le mot de passe</label>
                        <input type="password" id="confirmpassword" name="confirmpassword" v-model="confirmpassword" class="form-control" />
                        </div>                          
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="societe" class="float-left">Société</label>
                        <input type="text" id="societe" name="societe" class="form-control" v-model="user.societe" />
                        </div>                 
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="lnom" class="float-left">Nom</label>
                        <input type="text" id="lnom" name="lnom" class="form-control" v-model="user.nom" />
                        </div> 
                        <div class="col-5">
                        <label for="lprenom" class="float-left">Prénom</label>
                        <input type="text" id="lprenom" name="lprenom" class="form-control" v-model="user.prenom" />
                        </div>                                         
                    </div>

                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="fonction" class="float-left">Votre fonction</label>
                        <input type="text" id="fonction" name="fonction" class="form-control" v-model="user.fonction" />
                        </div>                 
                    </div>

                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/admin/parametres/adusers')" >Annuler</button>   

                </form>
            </div>
        <!-- </div>
    </div> -->
</div>    
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'AddUser',
    data() {
        return {
            user: {
                email: null,
                password: null,         
                nom: null,
                prenom: null,
                societe: null,   
                fonction: null,                                 
                role: 'MEMBRE',
                client: null,
            },

        }
    },
    methods: {
        async register() {
            if (this.user.password != this.confirmpassword) {
                            Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Les 2 mots de passe sont différents",
                });
            }
            else
            {
                try {
                    await axios.post("http://localhost:7007/auth/register", {
                        user: this.user
                    });
                    // Swal.fire({
                    //     icon: 'success',
                    //     title: 'Success!',
                    //     text: 'Sucessfully Registered. Now please check your mailbox for token to validate your email address',
                    // });
                    this.$router.push('/admin/parametres/adusers')                
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: e.response.data.message,
                    });
                }
            }
        }
    }
}
</script>