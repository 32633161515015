<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5"> -->
        <div class="col-md-5 m-auto">
            <div class="card card-header" style="background-color: white;">
                <h3>Connexion</h3>
            </div>            
            <div class="card card-body text-center">
                <form @submit.prevent="login()">
                    <div class="form-group">
                        <label for="email" class="float-left">Email</label>
                        <input type="email" id="email" name="email" class="form-control" v-model="email" />
                    </div>
                    <div class="form-group">
                        <label for="email" class="float-left">Mot de passe</label>
                        <input type="password" id="password" name="password" class="form-control" v-model="password" />
                    </div>
                    <button type="submit" class="btn btn-primary mt-2 mb-2">
                        Connexion
                    </button>

                </form>
                <div class="form-group m-b-0 m-t-10">
                    <div class="col-sm-10 text-center">
                        Vous n'avez pas de compte ?
                        <router-link to="/maroc/auth/registration" class="text-info m-l-5">Inscription</router-link>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>
</template>

<script>
import Swal from 'sweetalert2';

export default {

    name: 'UserLogin',
    data() {
        return {
            email: null,
            password: null
        };
    },
    methods: {
        async login() {
            try {
                const { data } = await this.$http.post("auth/login", {
                    email: this.email,
                    password: this.password
                });
                // Telling the Vuex store to commit the mutation named "LOGIN" with the provoided data
                this.$store.commit('LOGIN', data);
                localStorage.setItem("is_expanded", false)

                  // Swal.fire({
                //     icon: 'success',
                //     title: 'Success!',
                //     text: 'Sucessfully logged in',
                // });
                this.$router.push('/maroc/paie');
            } catch (e) {
                console.log(e)
                alert(e.response.data.message)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                    
                });
            }
        },
    },
}
</script>