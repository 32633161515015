<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5"> -->
        <div class="col-md-5 m-auto">
            <div class="card card-body text-center">
                <h3>Inscription</h3>
            </div>
            <div class="card card-body text-center">
                <form @submit.prevent="register()">

                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="email" class="float-left">Email</label>
                        <input type="email" id="email" name="email" class="form-control" v-model="user.email" />
                        </div>                 
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="email" class="float-left">Mot de passe</label>
                        <input type="password" id="password" name="password" class="form-control" v-model="user.password" />
                        </div>       
                        <div class="col-5">
                        <label for="email" class="float-left">Confirmez le mot de passe</label>
                        <input type="password" id="confirmpassword" name="confirmpassword" class="form-control" v-model="confirmpassword"/>
                        </div>                          
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="societe" class="float-left">Société</label>
                        <input type="text" id="societe" name="societe" class="form-control" v-model="user.societe" />
                        </div>                 
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="lnom" class="float-left">Nom</label>
                        <input type="text" id="lnom" name="lnom" class="form-control" v-model="user.nom" />
                        </div> 
                        <div class="col-5">
                        <label for="lprenom" class="float-left">Prénom</label>
                        <input type="text" id="lprenom" name="lprenom" class="form-control" v-model="user.prenom" />
                        </div>                                         
                    </div>

                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="fonction" class="float-left">Votre fonction</label>
                        <input type="text" id="fonction" name="fonction" class="form-control" v-model="user.fonction" />
                        </div>                 
                    </div>

                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/maroc')" >Annuler</button>   

                </form>
                <div class="form-group m-b-0 m-t-10">
                    <div class="col-sm-10 text-center">
                       Vous avez déjà un compte ?
                        <router-link to="/maroc/auth/login" class="text-info m-l-5">Connexion</router-link>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            user: {
                email: null,
                password: null,
                nom: null,
                prenom: null,
                societe: null,   
                fonction: null,                                 
                role: 'MEMBRE',
                client: null,
            },
            //roles: ['ADMIN', 'MEMBER']
        }
    },
    methods: {
        async register() {
            if (this.user.password != this.confirmpassword) {
                            Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Les 2 mots de passe sont différents",
                });
            }
            else
            {
        
                try {
                    await axios.post("https://paiemaroc.osc-fr1.scalingo.io/auth/register", {
                        user: this.user
                    });
                    Swal.fire({
                        icon: 'success',
                        title: 'Email enregistré !',
                        text: 'Votre inscription a été enregistrée. Vous devez confirmer votre inscription en saisissant le code envoyé à votre adresse email.',
                    });
                    this.$router.push(`verify-email?email=${this.user.email}`);
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: e.response.data.message,
                    });
                }
        }
        }
    }
}
</script>