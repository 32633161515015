<template lang="">

<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Modification Article {{this.codeid}}</h2>
                <form @submit.prevent="updateArticle(this.codeid)">
                    <div class="row mt-3" >
                        <div class="col">
                        <label for="titre" class="d-flex align-items-left">Titre</label>
                        <input type="text" id="titre" name="titre" class="form-control" v-model="articlem.titre" />    
                        </div>                        
                    </div>
                    <!-- <div class="row mt-2" >
                        <div class="col">
                        <label for="categorie" class="d-flex align-items-left">Catégorie</label>
                        <input type="text" id="categorie" name="categorie" class="form-control" v-model="articlem.categorie" />    
                        </div>                        
                    </div>                     -->
                    <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Catégorie</label>
                            <select v-model="articlem.categorie" class="form-control">
                            <option v-for="catarticle in catarticles" :value="catarticle?.categorie">
                                 {{ catarticle?.categorie }}
                            </option>
                            </select> 
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="urlarticle" class="d-flex align-items-left">Url</label>
                            <input type="text" id="urlarticle" name="titre" class="form-control" v-model="articlem.urlarticle" />    
                        </div>                        
                    </div>                    
                    <div class="row mt-2" >
                        <div class="col">
                        <label for="extrait" class="d-flex align-items-left">Extrait</label>
                        <textarea class="form-control" id="extrait" rows="3" v-model="articlem.extrait"></textarea>  
                        </div>                        
                    </div>                                    
                    <div class="row mt-2" >
                        <div class="col">
                        <label for="description" class="d-flex align-items-left">Description</label>
                        <textarea class="form-control" id="description" rows="10" v-model="articlem.description"></textarea>  
                        </div>                        
                    </div> 
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/maroc/admin/parametres/articles')" >Annuler</button>                                      
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'UpdateArticle',

    data() {
        return {
            articles: [],        
            article: [], 
            catarticles: [],  
            articlem: {
                titre: null,
                categorie: null,
                extrait: null,
                urlarticle: null,
                description: null,               
            },
            codeid: null,
            filters: {
                codeid: null
            },   
              

        }              
    },
    async mounted() {
    
        // Recherche du user
        // this.id = this.$route.params.code;
        this.codeid = this.$route.params.id; 
        try {
         
            this.filters.id = this.$route.params.id;  
            const { data } = await this.$http.get('/articles',
            {
                params: this.filters
            });
            this.article = data?.items;
        } catch (e) {
            console.error(e)
        }

        this.articlem.titre = this.article[0].titre;
        this.articlem.categorie = this.article[0].categorie; 
        this.articlem.extrait = this.article[0].extrait;    
        this.articlem.urlarticle = this.article[0].urlarticle;               
        this.articlem.description = this.article[0].description; 

        this.getCatarticles();                       
    },

 
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        async updateArticle(codeid) {
             try {
             
                this.filters.id = codeid; 
         
                await this.$http.put('/articles', this.articlem, {params: this.filters});                
                this.$router.push('/maroc/admin/parametres/articles')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        }, 
            async getCatarticles() {
            try {
                const { data } = await this.$http.get('/catarticles');
                this.catarticles = data?.items;
            } catch (e) {
                console.error(e)
            }
        },       
       

    }
}
</script>
<style lang="">

</style>