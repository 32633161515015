<template>
    <div>
  	<nav class="navbar bg-dark navbar-expand-lg border-bottom border-body" data-bs-theme="dark">
	<div class="container-fluid">
		<router-link class="navbar-brand" to="/">Paie.org</router-link>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarScroll">
		<ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
			<li class="nav-item"><router-link class="nav-link" to='/'>Accueil</router-link></li>
			
			<li class="nav-item dropdown" v-if="isAdmin()" >
			<span v-if="isAdmin()" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Admin</span>
			<div class="dropdown-menu" v-if="isAdmin()" >
				<router-link class="dropdown-item" v-if="isAdmin()" to='/maroc/admin/parametres/adusers'>Utilisateurs</router-link>           
				<router-link class="dropdown-item" v-if="isAdmin()" to='/maroc/admin/parametres/articles'>Articles</router-link>   
				<router-link class="dropdown-item" v-if="isAdmin()" to='/maroc/admin/parametres/contacts'>Contacts</router-link>
				<router-link class="dropdown-item" v-if="isAdmin()" to='/maroc/admin/parametres/catarticles'>Catégories Articles</router-link>             
				<div class="dropdown-divider" v-if="isAdmin()" ></div>             
				<router-link class="dropdown-item" v-if="isAdmin()" to='/maroc/admin/parametres/malistes'>Référentiels</router-link>  
				<router-link class="dropdown-item" v-if="isAdmin()" to='/maroc/admin/parametres/macpos'>Codes postaux</router-link>
				<router-link class="dropdown-item" v-if="isAdmin()" to='/maroc/admin/parametres/mapays'>Pays</router-link>            
			</div>
			</li>
			<li class="nav-item dropdown" v-if="islogged()" >
			<span v-if="isClient()" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Paramètrage</span>
			<div class="dropdown-menu" v-if="isClient()" >
				<router-link class="dropdown-item" v-if="isClient()" to='/maroc/paie/parametres/cladresse'>Adresses</router-link>       
				<router-link class="dropdown-item" v-if="isClient()" to='/maroc/paie/parametres/clbanque'>Banques</router-link>        
				<router-link class="dropdown-item" v-if="isClient()" to='/maroc/paie/parametres/clsoc'>Sociétés</router-link>  
				<router-link class="dropdown-item" v-if="isClient()" to='/maroc/paie/parametres/cleta'>Etablissements</router-link> 
			</div>
			</li>
			<li class="nav-item dropdown" >
 			
			<span class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Droit du travail</span>
			<div class="dropdown-menu">
				<router-link class="dropdown-item" to='/maroc/droit/travail'>Droit du travail</router-link>  
				<router-link class="dropdown-item" to='/maroc/droit/contrat-travail'>Contrats de travail</router-link>           
				<router-link class="dropdown-item" to='/maroc/droit/remuneration'>Rémunération</router-link>     
				<router-link class="dropdown-item" to='/maroc/droit/heures-de-travail'>Heures de travail</router-link> 		
				<router-link class="dropdown-item" to='/maroc/droit/conges'>Congés</router-link> 	
				<router-link class="dropdown-item" to='/maroc/droit/syndicats'>Syndicats</router-link> 	
				<router-link class="dropdown-item" to='/maroc/droit/inspection'>Inspection du travail</router-link> 
				<router-link class="dropdown-item" to='/maroc/droit/tribunaux-du-travail'>Tribunaux du travail</router-link> 	
				<router-link class="dropdown-item" to='/maroc/droit/travail-informel'>Travail informel</router-link> 																				         
			</div>
			</li>
			<li class="nav-item dropdown">
			<span class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Paie</span>
			<div class="dropdown-menu">
				<router-link class="dropdown-item" to='/maroc/paie/salaire'>Salaire de base</router-link>  
				<router-link class="dropdown-item" to='/maroc/paie/prime-anciennete'>Prime d'ancienneté</router-link>   				 
				<router-link class="dropdown-item" to='/maroc/paie/primes-imposables'>Primes imposables</router-link>      
				<router-link class="dropdown-item" to='/maroc/paie/cotisations-cnss'>Cotisations CNSS</router-link>      
				<router-link class="dropdown-item" to='/maroc/paie/cotisations-retraite'>Cotisations Retraite</router-link>      				
				<router-link class="dropdown-item" to='/maroc/paie/impot-revenu'>Impôt sur le revenu</router-link>    
				<router-link class="dropdown-item" to='/maroc/paie/indemnites-non-imposables'>Indemnités non imposables</router-link>     				               
			</div>
			</li>
			<li class="nav-item dropdown">
			<span class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Déclarations</span>
			<div class="dropdown-menu">
				<router-link class="dropdown-item" to='/maroc/declarations'>Les différentes déclaration</router-link>  
				<router-link class="dropdown-item" to='/maroc/declarations/cnss'>Déclaration CNSS</router-link>   
				<router-link class="dropdown-item" to='#'>Déclaration impôt sur le revenu (IR)</router-link> 
				<router-link class="dropdown-item" to='#'>Déclaration Annuelle des revenus (DAR)</router-link>  	
				<router-link class="dropdown-item" to='#'>Déclaration Cotisations Retraite (CMR)</router-link>  								 				  				               
			</div>
			</li>			
			<li><router-link class="nav-link" to='/maroc/contact'>Contact</router-link></li>
		</ul>
		<ul class="nav navbar-nav navbar-right">
				<li class="nav-item"><router-link v-if="!islogged()" class="nav-link" to='/maroc/auth/registration'><span class="glyphicon glyphicon-user"></span> Inscription</router-link></li>
				<li class="nav-item"><router-link v-if="!islogged()" class="nav-link" to='/maroc/auth/login'><span class="glyphicon glyphicon-log-in"></span> Connexion</router-link></li>
				<li class="nav-item"><router-link v-if="islogged()" class="nav-link" to='/maroc/auth/logout'><span class="glyphicon glyphicon-log-out"></span> Déconnexion</router-link></li>                  
				<li class="nav-item"><router-link v-if="islogged()" class="nav-link" to='/'><span class="glyphicon glyphicon-user"> {{this.currentUser.role }} {{this.currentUser.client}}</span></router-link></li>               
	
		</ul>

		</div>
	</div>
	</nav>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Header',

    computed: {
        ...mapGetters(['currentUser'])
    },    
    methods: {
          

        islogged() {
            if (this.currentUser) return true
        },
        isAdmin() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN") return true
            }
        }, 
        isClient() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN" || this.currentUser.role==="CLIENT") return true
            }
        },                               

    }
}
</script>

<style>

</style>