<template lang="">
<div class="jumbotron m-1 container-fluid">
<!-- 
    <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Modifier l'adresse  Client {{this.client}} {{this.code}}</h2>
                <form @submit.prevent="updateAdresse(this.client,this.code)">
                    <!-- <div class="row">
                        <div class="col-2">
                        <label for="code" class="d-flex align-items-left">Code</label>
                        <input type="text" id="code" name="code" class="form-control" v-model="this.code" /> 
                        </div>
                        <div class="col-1">
                        </div>
                        <div class="col-">                                       

                        </div>                  
                    </div> -->

                    <div class="row mt-3" >
                        <div class="col-2">
                        <label for="adr_num" class="d-flex align-items-left">Numéro</label>
                        <input type="text" id="adr_num" name="adr_num" class="form-control" v-model="this.adressem.adr_num" />  
                        </div>
                        <div class="col-3">
                        <label for="adr_bt" class="d-flex align-items-left">B/T</label>
                        <input type="text" id="adr_bt" name="adr_bt" class="form-control" v-model="this.adressem.adr_bt" /> 
                        </div>
                        <div class="col">                                       
                        <label for="adr_rue" class="d-flex align-items-left">Rue</label>
                        <input type="text" id="adr_rue" name="adr_rue" class="form-control" v-model="this.adressem.adr_rue" /> 
                        </div>                  
                    </div>
                    <div class="row mt-3">
                        <label for="adr_comp" class="d-flex align-items-left">Complément adresse</label>
                        <input type="text" id="adr_comp" name="adr_comp" class="form-control" v-model="adressem.adr_comp" />
                    </div>
                    <div class="row mt-3">
                        <div class="col-2">
                            <label for="adr_cpos" class="d-flex align-items-left">Code postal</label>
                            <input type="text" id="adr_cpos" name="adr_comp" class="form-control" v-model="adressem.adr_cpos" maxlength="5"/>
                        </div>
                        <!-- <div class="col-2">
                            <label for="adr_cpos" class="d-flex align-items-left">Code postal</label>

                            <select v-model="adressem.adr_cpos" class="form-control">
                            <option v-for="macpo in macpos" :value="macpo?.code">
                                {{ macpo?.code }} {{ macpo?.ville }}
                            </option>
                            </select>                     
                         </div> -->
                        <div class="col">                                       
                            <label for="adr_ville" class="d-flex align-items-left">Ville</label> 
                            <input type="text" id="adr_ville" name="adr_ville" class="form-control" v-model="adressem.adr_ville"/>
                        </div>
                        <div><p>{{ macpo?.ville }}</p></div>
                     </div>                                
                    <div class="row mt-3">
                        <label for="adr_pays" class="d-flex align-items-left">Pays</label>
                        <input type="text" id="adr_pays" name="adr_pays" class="form-control" v-model="adressem.adr_pays"  />
                    </div>
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/paie/parametres/cladresse')" >Annuler</button>                     
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'AddAdresse',

    data() {
        return {
            cladresses: [],        
            adresse: [],   
            adressem: {
                adr_num: null,
                adr_bt: null,
                adr_rue: null,
                adr_comp: null,
                adr_cpos: null,
                adr_ville: null,
                adr_pays: null               
            },
                            
            code: null,
            client: null,
            filters: {
                client: null,
                code: null
            },    
            macpos: {
                code: null,
                ville: null
            },
            selected: null,               

        }              
    },
    async mounted() {
    
        // Recherche de l'adresse
        this.code = this.$route.params.code;
        this.client = this.$route.params.client;
        try {
            this.filters.code = this.$route.params.code;           
            this.filters.client = this.$route.params.client;   
            const { data } = await this.$http.get('/cladresses',
            {
                params: this.filters
            });
            this.adresse = data?.items;
        } catch (e) {
            console.error(e)
        }
        this.adressem.adr_num = this.adresse[0].adr_num;
        this.adressem.adr_bt = this.adresse[0].adr_bt; 
        this.adressem.adr_rue = this.adresse[0].adr_rue; 
        this.adressem.adr_comp = this.adresse[0].adr_comp; 
        this.adressem.adr_cpos = this.adresse[0].adr_cpos; 
        this.adressem.adr_ville = this.adresse[0].adr_ville;
        this.adressem.adr_pays = this.adresse[0].adr_pays;

        // Chargement des codes postaux
    //    this.getMacpos();

    },

 
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        async updateAdresse(client,code) {
             try {

                this.filters.client = client; 
                this.filters.code = code;  
                await this.$http.put('/cladresses', this.adressem, {params: this.filters});                
                this.$router.push('/paie/parametres/cladresse')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },  

        // async getMacpos() {
        //     try {
        //         const { data } = await this.$http.get('/macpos');
        //         this.macpos = data?.items;

        //     } catch (e) {
        //         console.error(e)
        //     }
        // },         

    }
}
</script>
<style lang="">

</style>