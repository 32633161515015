import { jwtDecode } from "jwt-decode";

// Define the class named User
export default class User {
    // Constructor to intiate the User Object
    constructor({ id, email, role, nom, prenom, client, societe, fonction,email_verfication }) {
        this.id = id;
        this.email = email;
        this.role = role;
        this.client = client;        
        this.nom = nom;
        this.prenom = prenom;
        this.societe = societe; 
        this.fonction = fonction;             
        this.name = `${this.nom} ${this.prenom}`;
        this.isVerified = email_verfication === 'VERIFIED'
    }

    // Static method to create a User object from a JWT token
    static from(token) {
        try {
            let obj = jwtDecode(token); // Decode jwt token
            return new User(obj);       // Create a new User Object with the decoded data
        } catch (e) {
            return null;
        }
    }
}