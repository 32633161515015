<template lang="">
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Ajouter une société</h2>
                <form @submit.prevent="addSociete()">
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="adr_num" class="d-flex align-items-left">Raison sociale</label>
                            <input type="text" id="rsoc" name="rsoc" class="form-control" v-model="soc.rsoc" maxlength="50" />    
                        </div>               
                    </div>
                    <div class="row mt-3" >
                        <!-- <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Code adresse</label>
                            <input type="text" id="code_adr" name="code_adr" class="form-control" v-model="soc.code_adr" maxlength="3"/>    
                        </div>   -->

                        <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Adresse</label>
                            <select v-model="soc.code_adr" class="form-control">
                            <option v-for="cladresse in cladresses" :value="cladresse?.code">
                                 {{ cladresse?.code }} - {{ cladresse?.adr_num }} {{ cladresse?.adr_rue }} {{ cladresse?.adr_cpos }} {{ cladresse?.adr_ville }}
                            </option>
                            </select> 
                        </div>                        
                         <!-- <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Code banque</label>
                            <input type="text" id="code_bnq" name="code_bnq" class="form-control" v-model="soc.code_bnq" maxlength="3" />    
                        </div> -->

                        <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Banque</label>
                            <select v-model="soc.code_bnq" class="form-control">
                            <option v-for="clbanque in clbanques" :value="clbanque?.code">
                                 {{ clbanque?.code }} - {{ clbanque?.banque }} Compte {{ clbanque?.code_compte }}
                            </option>
                            </select> 
                        </div>                          
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="cice" class="d-flex align-items-left">Code CIE</label>
                            <input type="text" id="cice" name="cice" class="form-control" v-model="soc.soc_cice" maxlength="9" />    
                        </div>  
                         <div class="col">
                            <label for="rcom" class="d-flex align-items-left">Registre du commerce</label>
                            <input type="text" id="rcom" name="rcom" class="form-control" v-model="soc.soc_rcom" maxlength="20" />    
                        </div>                                        
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="cice" class="d-flex align-items-left">Identifiant fiscal</label>
                            <input type="text" id="ifis" name="ifis" class="form-control" v-model="soc.soc_ifis" maxlength="20"  />    
                        </div>  
                         <div class="col">
                            <label for="cpat" class="d-flex align-items-left">Patente</label>
                            <input type="text" id="cpat" name="cpat" class="form-control" v-model="soc.soc_cpat" maxlength="20" />    
                        </div>                                        
                    </div>
                    <div class="row mt-3" >
                        <div class="col-3">
                            <label for="cnss" class="d-flex align-items-left">Identifiant CNSS</label>
                            <input type="text" id="cnss" name="cnss" class="form-control" v-model="soc.soc_cnss" maxlength="20" />    
                        </div>                                         
                    </div>                               
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/paie/parametres/clsoc')" >Annuler</button>                                    

                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'AddSociete',
    data() {
        return {
            soc: {
                rsoc: null,
                code_adr: null,
                code_bnq: null,
                soc_cice: null,
                soc_rcom: null,
                soc_ifis: null,
                soc_cpat: null,
                soc_cnss: null           
                     
            },
            total: null,
            filters: {
                client: null
            },  
            clbanques: [],
            cladresses: [],            
            selected: null,                      
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    },  

    // Chargement des codes adresses
    mounted() {
       this.getAdresses();    
       this.getBanques();
    },    

    methods: {
        async addSociete() {

            try {
                this.filters.client = this.currentUser.client;   
                const { data } = await this.$http.get('/clsocs',
                {
                     params: this.filters
                });
                this.clsocs = data?.items;
                var btCodenum = "000"+( Number(this.clsocs.length) +1).toString();
                this.soc.client = this.currentUser.client;   
                this.soc.code = btCodenum.substr(btCodenum.length-3);

            } catch (e) {
                console.error(e)
            }
            try {
                await this.$http.post('/clsocs', this.soc);
                this.$router.push('/paie/parametres/clsoc')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },

        async getAdresses() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client;   
            const { data } = await this.$http.get('/cladresses',
            {
                params: this.filters
            });
            this.cladresses = data?.items;

        },

        async getBanques() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client;   
            const { data } = await this.$http.get('/clbanques',
            {
                params: this.filters
            });
            this.clbanques = data?.items;

        },         
                
    }
}
</script>
<style lang="">

</style>