<template>

	<aside :class="`${is_expanded ? 'is-expanded' : ''}`">
		<div class="menu">
      <h3><b>Structure juridique</b></h3>    
      <router-link to="/maroc/paie/parametres/clsoc" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Sociétés</span>
      </router-link>
      <router-link to="/maroc/paie/parametres/cleta" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Etablissements</span>
			</router-link>  
      <h3><b>Paramètres</b></h3>    
      <router-link to="/maroc/paie/parametres/cladresse" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Adresses</span>
			</router-link>  
      <router-link to="/maroc/paie/parametres/clbanque" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Banques</span>
			</router-link> 	
      <h3><b>Administration</b></h3>			
      <router-link to="/maroc/admin/parametres/adusers" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Utilisateurs</span>
			</router-link> 	
      <router-link to="/maroc/admin/parametres/articles" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Articles</span>
			</router-link> 	  
      <router-link to="/maroc/admin/parametres/catarticles" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Catégories d'articles</span>
			</router-link>
      <router-link to="/maroc/admin/parametres/contacts" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Contacts</span>
			</router-link>   
      <router-link to="/maroc/admin/parametres/macpos" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Codes postaux</span>
			</router-link>    
      <router-link to="/maroc/admin/parametres/mapays" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Pays</span>
			</router-link>  
      <router-link to="/maroc/paie" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
      <span class="text">Quitter</span>
			</router-link>                                     
		</div>


	</aside>
</template>

<script>
const is_expanded = ref(localStorage.getItem("is_expanded") === "false")
import { mapGetters } from 'vuex';
import { ref } from 'vue';
export default {
    name: 'ParametresNav',
    data() {
         return {
             is_expanded,
         }
    },

    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: { 
        affiche() {
	        // is_expanded.value = !is_expanded.value
        },
        affiche_true() {
	        is_expanded.value = true
        }, 
        affiche_false() {
	        is_expanded.value = true
        },                 
        ToggleMenu() {
	        is_expanded.value = !is_expanded.value
	        localStorage.setItem("is_expanded", is_expanded.value)
        },    
        isAdmin() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN") return true
            }
        }, 
        isClient() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN" || this.currentUser.role==="CLIENT") return true
            }
        },   
    }
}
</script>
<style lang="scss" scoped>

.appflex {
  	display:flex;
} 
aside {
	display: flex;
	flex-direction: column;
	background-color: #d7d8d9;
	color: var(--light);

	width: calc(2rem + 32px);
	overflow: hidden;
	min-height: 100vh;
	padding: 1rem;

	transition: 0.2s ease-in-out;

	.flex {
		flex: 1 1 0%;
	}

	.logo {
		margin-bottom: 1rem;

		img {
			width: 2rem;
		}
	}

	.menu-toggle-wrap {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 1rem;
		position: relative;
		top: 0;
		transition: 0.2s ease-in-out;

		.menu-toggle {
			transition: 0.2s ease-in-out;
			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-out;
			}
			
			&:hover {
				.material-icons {
					color: var(--primary);
					transform: translateX(0.5rem);
				}
			}
		}
	}

	h3, .button .text {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	h3 {
		color: black;
		font-size: 0.9rem;
		margin-bottom: 0.5rem;
  	margin-top: 0.5rem;  
  	margin-left: 0.5rem;  
		// text-transform: uppercase;
	}

	.menu {
		margin: 0 -1rem;

		.button {
			display: flex;
			align-items: center;
			text-decoration: none;

			transition: 0.2s ease-in-out;
			padding: 0.5rem 1rem;

			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-in-out;
			}
			.text {
				color: black;
				transition: 0.2s ease-in-out;
			}

			&:hover {
				background-color: grey;

				.material-icons, .text {
					color: var(--primary);
				}
			}

			&.router-link-exact-active {
				background-color: grey;
				border-right: 4px solid var(--primary);

				.material-icons, .text {
					color: var(--primary);
				}
			}
		}
	}

	.footer {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		p {
			font-size: 0.875rem;
			color: var(--grey);
		}
	}

	&.is-expanded {
		width: var(--sidebar-width);

		.menu-toggle-wrap {
			top: -3rem;
			
			.menu-toggle {
				transform: rotate(-180deg);
			}
		}

		h3, .button .text {
			opacity: 1;
		}

		.button {
			.material-icons {
				margin-right: 1rem;
			}
		}

		.footer {
			opacity: 0;
		}
	}

	@media (max-width: 1024px) {
		position: absolute;
		z-index: 99;
	}
}
</style>