<template lang="">

<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Modification Contact {{this.codeid}}</h2>
                <form @submit.prevent="updateContact(this.codeid)">
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="email" class="float-left">Email</label>
                        <input type="email" id="email" name="email" class="form-control" v-model="contactm.email" disabled/>
                        </div>                 
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="lnom" class="float-left">Nom</label>
                        <input type="text" id="lnom" name="lnom" class="form-control" v-model="contactm.nom" />
                        </div> 
                        <div class="col-5">
                        <label for="lsociete" class="float-left">Société</label>
                        <input type="text" id="lsociete" name="lprenom" class="form-control" v-model="contactm.societe" />
                        </div>                                         
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="telephone" class="float-left">Téléphone</label>
                        <input type="text" id="telephone" name="societe" class="form-control" v-model="contactm.telephone"  />
                        </div>                                         
                    </div>

                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="role" class="float-left">Message</label>
                        <textarea class="form-control" id="message" rows="3" v-model="contactm.message"></textarea>  
                        </div>                                      
                    </div>
                 
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/maroc/admin/parametres/contacts')" >Annuler</button>                                      
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'UpdateContact',

    data() {
        return {
            contacts: [],        
            contact: [],   
            contactm: {
                email: null,
                nom: null,
                societe: null,
                message: null,   
                telephone: null,                            
            },
            codeid: null,
            filters: {
                codeid: null
            },    
              

        }              
    },
    async mounted() {
    
        // Recherche du user
        // this.id = this.$route.params.code;
        this.codeid = this.$route.params.id;    

        try {
         
            this.filters.id = this.$route.params.id;  
            const { data } = await this.$http.get('/contacts',
            {
                params: this.filters
            });
            this.contact = data?.items;
        } catch (e) {
            console.error(e)
        }

        this.contactm.email = this.contact[0].email;
        this.contactm.nom = this.contact[0].nom;
        this.contactm.societe = this.contact[0].societe;
        this.contactm.telephone = this.contact[0].telephone;
        this.contactm.message = this.contact[0].message;        
 
          
    },

 
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        async updateContact(codeid) {
             try {
             
                this.filters.id = codeid; 
         
                await this.$http.put('/contacts', this.contactm, {params: this.filters});                
                this.$router.push('/maroc/admin/parametres/contacts')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },  
       

    }
}
</script>
<style lang="">

</style>