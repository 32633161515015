<template>

    <div class="row">
        <div class="col-8">
            <div>

              <div class="jumbotron m-1 container-fluid">
                <section class="bg-light py-3 py-md-5">
                  <div class="container">
                    <div class="row justify-content-lg-center">
                      <div >
                        <div class="bg-white border rounded shadow-sm overflow-hidden">
                          <div class="card card-body text-center">
                            <h3>Nous contacter</h3>
                          </div>
                          <form @submit.prevent="register()">
                            <div class="row gy-4 gy-xl-5 p-4 p-xl-5">
                              <div class="col-12 col-md-6">
                                <label for="nom" class="form-label">Nom <span class="text-danger">*</span></label>
                                <div class="input-group">
                                  <input type="nom" class="form-control" id="nom" name="nom"  v-model="contact.nom"  required>
                                </div>
                              </div>
                              <div class="col-12 col-md-6">
                            <label for="societe" class="form-label">Société</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="societe" name="societe" v-model="contact.societe" required>
                            </div>
                          </div>                            
                          <div class="col-12 col-md-6">
                            <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
                            <div class="input-group">
                              <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                </svg>
                              </span>
                              <input type="email" class="form-control" id="email" name="email" v-model="contact.email" required>
                          
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <label for="telephone" class="form-label">Téléphone</label>
                            <div class="input-group">
                              <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                </svg>
                              </span>
                              <input type="text" class="form-control" id="telephone" name="telephone" v-model="contact.telephone">
                            </div>
                          </div>
                          <div class="col-12">
                            <label for="message" class="form-label">Message <span class="text-danger">*</span></label>
                            <textarea class="form-control" id="message" name="message" rows="3"  v-model="contact.message" required></textarea>
                          </div>
                          <div class="col-12">
                            <!-- <div class="d-grid"> -->
                                <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                            <!-- </div> -->
                          </div>
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              </div>
            </div>
        </div>
        <div class="col">
            <div class="container m-4">
                <h4 class="text-center"><b>La paie au Maroc</b></h4>
            </div>    
            <BarreDroite/>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import BarreDroite from './BarreDroite.vue';

export default {
    name: 'Contact',
    components: {
      BarreDroite
    },
    data() {
        return {
            contact: {
                nom: null,
                societe: null,  
                telephone: null,       
                email: null,
                message: null,
            },

        }
    },
    methods: {
        async register() {
   
          try {
              await axios.post("https://paiemaroc.osc-fr1.scalingo.io/contact", this.contact);
              Swal.fire({
                icon: 'success',
                title: 'Votre message a été envoyé!',
                text: 'Nous vous contacterons dans les plus brefs délais',
              });
              this.$router.push('/')                
          } catch (e) {
              Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: e.response.data.message,
              });
          }
        }
    }
}
</script>