<template lang="">
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Ajouter un article </h2>
                <form @submit.prevent="addArticle()">
                    <div class="row mt-3" >
                        <div class="col">
                        <label for="titre" class="d-flex align-items-left">Titre</label>
                        <input type="text" id="titre" name="titre" class="form-control" v-model="article.titre" />    
                        </div>                        
                    </div>
                    <!-- <div class="row mt-2" >
                        <div class="col">
                        <label for="categorie" class="d-flex align-items-left">Catégorie</label>
                        <input type="text" id="categorie" name="categorie" class="form-control" v-model="article.categorie" />    
                        </div>                        
                    </div>                     -->
                    <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Catégorie</label>
                            <select v-model="article.categorie" class="form-control">
                            <option v-for="catarticle in catarticles" :value="catarticle?.categorie">
                                 {{ catarticle?.categorie }}
                            </option>
                            </select> 
                    </div>   
                    <div class="row mt-3" >
                        <div class="col">
                        <label for="urlarticle" class="d-flex align-items-left">Url</label>
                        <input type="urlarticle" id="titre" name="titre" class="form-control" v-model="article.urlarticle" />    
                        </div>                        
                    </div>                    
                    <div class="row mt-2" >
                        <div class="col">
                        <label for="extrait" class="d-flex align-items-left">Extrait</label>
  
                        <textarea class="form-control" id="extrait" rows="3" v-model="article.extrait"></textarea>    
                        </div>
                    </div>                                       
                    <div class="row mt-2" >
                        <div class="col">
                        <label for="description" class="d-flex align-items-left">Description</label>
  
                        <textarea class="form-control" id="description" rows="10" v-model="article.description"></textarea>    
                        </div>
                    </div> 
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/maroc/admin/parametres/articles')" >Annuler</button>                                    
                </form>
            </div>
        <!-- </div>
    </div> -->
    </div>
 
</template>
<script>


import Swal from 'sweetalert2';

export default {
    name: 'AddArticle',

    data() {
        return {
            article: {
                titre: null,
                categorie: null,
                urlarticle: null,
                extrait: null,
                description: null,       

            },
            catarticles: [],

        }              
    },
    mounted() {
       this.getCatarticles();    

    }, 
    methods: {
        async addArticle() {

            try {
                await this.$http.post('/articles', this.article);
                this.$router.push('/maroc/admin/parametres/articles')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },
        async getCatarticles() {
            try {
                const { data } = await this.$http.get('/catarticles');
                this.catarticles = data?.items;
            } catch (e) {
                console.error(e)
            }
        },        
     

    }
}
</script>
<style lang="">

</style>