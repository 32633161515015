<template>
<div v-if="!isClient()">
	<Header></Header>
	<router-view />    	
</div>

<div v-if="isClient()" class="appflex">
	<Sidebar ></Sidebar>  
	<router-view />    		
</div>
	<!-- <div v-if="!isClient()" class="app">
		<PublicNav ></PublicNav>
	    <router-view />    		
	</div> -->
	<div v-if="!isClient()" class="app">
		<Footer ></Footer>   		
	</div>

</template>

<script >
import Sidebar from './components/Sidebar.vue';
import PublicNav from '../src/components/PublicNav.vue';
import Footer from './views/public/Footer.vue';
import BarreDroite from './views/public/BarreDroite.vue';
import Header from './views/public/Header.vue';
import { mapGetters } from 'vuex';
export default {

  	name: 'App',
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'La paie au Maroc';
            }
        },
    },		
	components: {
        PublicNav,
		Sidebar,
		Footer,
		BarreDroite,
		Header,
    }, 

	computed: {
        ...mapGetters(['currentUser'])
    },
	
    methods: {
	
		isClient() {
			if (this.currentUser) {
				if (this.currentUser.role==="ADMIN" || this.currentUser.role==="CLIENT") return true
			}
	}
	}, 
}
</script>

<style lang ="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}
:root {
	--primary: #f1f5f9;
	--primary-alt: #f1f5f9;
	--grey: #64748b;
	--dark: #1e293b;
	--dark-alt: #334155;
	--light: #f1f5f9;
	--sidebar-width: 300px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Fira sans', sans-serif;
}

body {
	background: var(--light);
}

button {
	cursor: pointer;
	appearance: none;
	border: none;
	outline: none;
	background: none;
}

.appflex {
	display: flex;

	main {
		flex: 1 1 0;
		padding: 2rem;

		@media (max-width: 1024px) {
			padding-left: 6rem;
		}
	}
}
.app {
	// display: flex;

	main {
		flex: 1 1 0;
		padding: 2rem;


		@media (max-width: 1024px) {
			padding-left: 6rem;
		}
	}
}
</style>
