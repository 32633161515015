<template lang="">

<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5"> -->
        <!-- <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Modifier la société {{this.client}} {{this.code}}</h2>
                <form @submit.prevent="updateSociete(this.client,this.code)">
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="adr_num" class="d-flex align-items-left">Raison sociale</label>
                            <input type="text" id="rsoc" name="rsoc" class="form-control" v-model="socm.rsoc" maxlength="50" />    
                        </div>               
                    </div>
                    <div class="row mt-3" >
                        <!-- <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Code adresse</label>
                            <input type="text" id="code_adr" name="code_adr" class="form-control" v-model="socm.code_adr" maxlength="3"/>    
                        </div>   -->

                        <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Adresse</label>
                            <select v-model="socm.code_adr" class="form-control">
                            <option v-for="cladresse in cladresses" :value="cladresse?.code">
                                {{ cladresse?.code }} - {{ cladresse?.adr_rue }} {{ cladresse?.adr_cpos }} {{ cladresse?.adr_ville }}
                            </option>
                            </select> 
                        </div>                         
                         <!-- <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Code banque</label>
                            <input type="text" id="code_bnq" name="code_bnq" class="form-control" v-model="socm.code_bnq" maxlength="3" />    
                        </div>      -->
                        <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Banque</label>
                            <select v-model="socm.code_bnq" class="form-control">
                            <option v-for="clbanque in clbanques" :value="clbanque?.code">
                                 {{ clbanque?.code }} - {{ clbanque?.banque }} Compte {{ clbanque?.code_compte }}
                            </option>
                            </select> 
                        </div>                          
                                                           
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="cice" class="d-flex align-items-left">Code CIE</label>
                            <input type="text" id="cice" name="cice" class="form-control" v-model="socm.soc_cice" maxlength="9" />    
                        </div>  
                         <div class="col">
                            <label for="rcom" class="d-flex align-items-left">Registre du commerce</label>
                            <input type="text" id="rcom" name="rcom" class="form-control" v-model="socm.soc_rcom" maxlength="20" />    
                        </div>                                        
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="cice" class="d-flex align-items-left">Identifiant fiscal</label>
                            <input type="text" id="ifis" name="ifis" class="form-control" v-model="socm.soc_ifis" maxlength="20"  />    
                        </div>  
                         <div class="col">
                            <label for="cpat" class="d-flex align-items-left">Patente</label>
                            <input type="text" id="cpat" name="cpat" class="form-control" v-model="socm.soc_cpat" maxlength="20" />    
                        </div>                                        
                    </div>
                    <div class="row mt-3" >
                        <div class="col-3">
                            <label for="cnss" class="d-flex align-items-left">Identifiant CNSS</label>
                            <input type="text" id="cnss" name="cnss" class="form-control" v-model="socm.soc_cnss" maxlength="20" />    
                        </div>                                         
                    </div>                               
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/paie/parametres/clsoc')" >Annuler</button>                    
                </form>
            </div>
        <!-- </div> -->
    <!-- </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'AddAdresse',

    data() {
        return {
            clsocs: [],        
            soc: [],   
            socm: {
                rsoc: null,
                code_adr: null,
                code_bnq: null,
                soc_cice: null,
                soc_rcom: null,
                soc_ifis: null,
                soc_cpat: null,
                soc_cnss: null                   
            },
                            
            code: null,
            client: null,
            filters: {
                client: null,
                code: null
            },                            
            cladresses: [],
            clbanques: [],
            selected: null,        
        }              
    },
    async mounted() {
    
        this.code = this.$route.params.code;
        this.client = this.$route.params.client;
        try {
            this.filters.code = this.$route.params.code;           
            this.filters.client = this.$route.params.client;   
            const { data } = await this.$http.get('/clsocs',
            {
                params: this.filters
            });
            this.soc = data?.items;
        } catch (e) {
            console.error(e)
        }

        this.socm.rsoc = this.soc[0].rsoc;
        this.socm.code_adr = this.soc[0].code_adr;
        this.socm.code_bnq = this.soc[0].code_bnq;
        this.socm.soc_cice = this.soc[0].soc_cice;
        this.socm.soc_rcom = this.soc[0].soc_rcom;
        this.socm.soc_ifis = this.soc[0].soc_ifis;
        this.socm.soc_cpat = this.soc[0].soc_cpat;
        this.socm.soc_cnss = this.soc[0].soc_cnss;
        // Chargement des codes adresses
        this.getAddresses();
        this.getBanques();     

    },

    // Chargement des codes adresses

 
    computed: {
        ...mapGetters(['currentUser'])
    },
   

    methods: {
        async updateSociete(client,code) {
             try {

                this.filters.client = client; 
                this.filters.code = code;  
                await this.$http.put('/clsocs', this.socm, {params: this.filters});                
                this.$router.push('/paie/parametres/clsoc')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },  
        async getAddresses() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client; 
                this.filters.code = '';                    
            const { data } = await this.$http.get('/cladresses',
            {
                params: this.filters
            });
            this.cladresses = data?.items;

        },     
        async getBanques() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client; 
                this.filters.code = '';                    
            const { data } = await this.$http.get('/clbanques',
            {
                params: this.filters
            });
            this.clbanques = data?.items;

        },     

    }
}
</script>
<style lang="">

</style>