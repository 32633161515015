<template lang="">
    <ParametresNav />
    <router-view />  

</template>
<script>

import ParametresNav from './ParametresNav.vue';

export default {
    name: 'ParametresLayout',
    components: {
        ParametresNav,
    },  
}
</script>
<style>

</style>