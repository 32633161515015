<template lang="">

<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Modification Utilisateur {{this.codeid}}</h2>
                <form @submit.prevent="updateUser(this.codeid)">
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="email" class="float-left">Email</label>
                        <input type="email" id="email" name="email" class="form-control" v-model="userm.email" disabled/>
                        </div>                 
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="lnom" class="float-left">Nom</label>
                        <input type="text" id="lnom" name="lnom" class="form-control" v-model="userm.nom" />
                        </div> 
                        <div class="col-5">
                        <label for="lprenom" class="float-left">Prénom</label>
                        <input type="text" id="lprenom" name="lprenom" class="form-control" v-model="userm.prenom" />
                        </div>                                         
                    </div>
                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="societe" class="float-left">Société</label>
                        <input type="text" id="societe" name="societe" class="form-control" v-model="userm.societe"  />
                        </div>    
                        <div class="col-5">
                        <label for="fonction" class="float-left">Fonction</label>
                        <input type="text" id="fonction" name="fonction" class="form-control" v-model="userm.fonction" />
                        </div>                                        
                    </div>

                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="role" class="float-left">Role</label>
                        <input type="text" id="role" name="role" class="form-control" v-model="userm.role" />
                        </div> 
                        <div class="col-5">
                        <label for="client" class="float-left">Client</label>
                        <input type="text" id="client" name="client" class="form-control" v-model="userm.client" />
                        </div>                                         
                    </div>

                    <div class="row mt-3" >
                        <div class="col-5">
                        <label for="emailverif" class="float-left">Email vérification</label>
                        <input type="text" id="emailverif" name="emailverif" class="form-control" v-model="userm.email_verification" />
                        </div> 
                                       
                    </div>                    
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/admin/parametres/adusers')" >Annuler</button>                                      
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'UpdateUser',

    data() {
        return {
            adusers: [],        
            user: [],   
            userm: {
                email: null,
                nom: null,
                prenom: null,
                fonction: null,
                societe: null,
                fonction: null,
                role: null,
                client: null,
                email_verification: null               
            },
            codeid: null,
            filters: {
                codeid: null
            },    
              

        }              
    },
    async mounted() {
    
        // Recherche du user
        // this.id = this.$route.params.code;
        this.codeid = this.$route.params.id;    

        try {
         
            this.filters.id = this.$route.params.id;  
            const { data } = await this.$http.get('/adusers',
            {
                params: this.filters
            });
            this.user = data?.items;
        } catch (e) {
            console.error(e)
        }

        this.userm.email = this.user[0].email;
        this.userm.nom = this.user[0].nom;
        this.userm.prenom = this.user[0].prenom;
        this.userm.fonction = this.user[0].fonction;
        this.userm.societe = this.user[0].societe;
        this.userm.role = this.user[0].role;    
        this.userm.client = this.user[0].client;        
        this.userm.email_verification = this.user[0].email_verification;              
    },

 
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        async updateUser(codeid) {
             try {
             
                this.filters.id = codeid; 
         
                await this.$http.put('/adusers', this.userm, {params: this.filters});                
                this.$router.push('/maroc/admin/parametres/adusers')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },  
       

    }
}
</script>
<style lang="">

</style>