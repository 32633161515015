<template lang="">
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Modification de catégorie d'articles {{this.codeid}}</h2>
                <form @submit.prevent="updateCatArticle(this.codeid)">
                    <div class="row mt-2" >
                        <div class="col">
                        <label for="categorie" class="d-flex align-items-left">Catégorie</label>
                        <input type="text" id="categorie" name="categorie" class="form-control" v-model="catarticlem.categorie" />    
                        </div>                        
                    </div>                     
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/admin/parametres/catarticles')" >Annuler</button>                                      
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'UpdateCatArticle',

    data() {
        return {
            catarticles: [],        
            catarticle: [],   
            catarticlem: {
                categorie: null,
         
            },
            codeid: null,
            filters: {
                codeid: null
            },   
              

        }              
    },
    async mounted() {
    
        // Recherche du user
        // this.id = this.$route.params.code;
        this.codeid = this.$route.params.id; 
        try {
         
            this.filters.id = this.$route.params.id;  
            const { data } = await this.$http.get('/catarticles',
            {
                params: this.filters
            });
            this.catarticle = data?.items;
        } catch (e) {
            console.error(e)
        }

        this.catarticlem.categorie = this.catarticle[0].categorie;                 
    },

 
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        async updateCatArticle(codeid) {
             try {
             
                this.filters.id = codeid; 
         
                await this.$http.put('/catarticles', this.catarticlem, {params: this.filters});                
                this.$router.push('/admin/catarticles')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },  
       

    }
}
</script>
<style lang="">

</style>