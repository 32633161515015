<template>
    <div class="row">
        <div class="col-8">
            <div class="container m-2">
                <h2 class="text-center">Types de contrat de travail</h2>
                <div class="jumbotron m-1 container-fluid" v-for="( article, index ) of articles" :key="index">
                    <div class="card card-body" >
                            <div class="jumbotron m-3 container-fluid" >
                                <div class="card">
                                    <div class="card-header"><h5><b>{{ article?.titre }}</b></h5></div>
                                    <div class="card-body v-html" >
                                        <span v-html="article?.description"></span>
                                    </div>
                                <div class="card-footer"><p class="card-text"><b>Catégorie : </b>{{ article?.categorie }} - <b>Auteur : </b>Paie.org</p></div>          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Barre droite -->
        <div class="col">
            <BarreDroite/>              
        </div>
    </div>
</template>
<script>
import BarreDroite from '../public/BarreDroite.vue';
export default {
    name: 'DroitContratsTravail',
    components: {
        BarreDroite,
    },
    data() {
        return {
            articles: [],
            filters: {
                id: null
            },
        }
    },
  
    mounted() {
        this.getArticles()
    },
    methods: {
   
        async getArticles() {
            try {
                this.filters.id = 2; 
                const { data } = await this.$http.get('/articles',

                {
                    params: this.filters
                });
                this.articles = data?.items;
            } catch (e) {
                console.error(e)
            }
        },


    }

}
</script>
<style>

</style>