<template>

	<aside :class="`${is_expanded ? 'is-expanded' : ''}`">
		<div class="logo" >
        	<button class="menu-toggle" @click="ToggleMenu">
			    <img src="../assets/logo.png" alt="Vue"/> 
            </button>
		</div>

		<!-- <div class="menu-toggle-wrap">
			<button class="menu-toggle" @click="ToggleMenu" >
				<span class="material-icons">keyboard_double_arrow_right</span>
			</button>
		</div> -->

		<h3>Menu</h3>
		<div class="menu">
			<router-link to="/maroc/paie" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
				<span class="material-icons">home</span>
				<span class="text">Accueil</span>
			</router-link>
			<router-link to="/maroc/paie" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
				<span class="material-icons">group</span>
				<span class="text">Salariés</span>
			</router-link>
			<router-link to="/maroc/paie" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
				<span class="material-icons">ballot</span>
				<span class="text">Déclarations</span>
			</router-link>	
			<router-link to="/maroc/paie" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
				<span class="material-icons">type_specimen</span>
				<span class="text">Etats</span>
			</router-link>					
			<router-link to="/maroc/paie/contact" class="button" @mouseover="affiche_true()"  @mouseout="affiche_false()">
				<span class="material-icons">email</span>
				<span class="text">Contact</span>
			</router-link>
		</div>

		<!-- <div class="flex"></div> -->
		
		<div class="menu" >

			<router-link to="/maroc/paie/parametres/clsoc" class="button"  @mouseover="affiche_true()"  @mouseout="affiche_false()">
				<span class="material-icons">settings</span>
				<span class="text">Paramètres</span>
			</router-link>
			<router-link to="/maroc/auth/logout" class="button"  @mouseover="affiche_true()"  @mouseout="affiche_false()">
				<span class="material-icons">logout</span>
				<span class="text">Déconnexion</span>
			</router-link>

		</div>

	</aside>

</template>
<script>

const is_expanded = ref(localStorage.getItem("is_expanded") === "true")

import { ref } from 'vue';
import { mapGetters } from 'vuex';
export default {
    name: 'PaieNav',
    data() {
         return {
             is_expanded,
         }
    },
    computed: {
        ...mapGetters(['currentUser'])
    },

    mounted() {

    }, 
    
    methods: {
        affiche() {
	        is_expanded.value = !is_expanded.value
        },
        affiche_true() {
	        is_expanded.value = true
        }, 
        affiche_false() {
	        is_expanded.value = false
        },                 
        ToggleMenu() {
	        is_expanded.value = !is_expanded.value
	        localStorage.setItem("is_expanded", is_expanded.value)
        },
        islogged() {
            if (this.currentUser) return true
        },
        isAdmin() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN") return true
            }
        }, 
        isClient() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN" || this.currentUser.role==="CLIENT") return true
            }
        },                               
        logout() {
            this.$store.commit('LOGOUT');
            this.$router.replace('/auth/login')
        }

    }       
}

</script>

<style lang="scss" scoped>
aside {
	display: flex;
	flex-direction: column;
	background-color: var(--dark);
	color: var(--light);

	width: calc(2rem + 32px);
	overflow: hidden;
	min-height: 100vh;
	padding: 1rem;

	transition: 0.2s ease-in-out;

	.flex {
		flex: 1 1 0%;
	}

	.logo {
		margin-bottom: 1rem;

		img {
			width: 2rem;
		}
	}

	.menu-toggle-wrap {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 1rem;
		position: relative;
		top: 0;
		transition: 0.2s ease-in-out;

		.menu-toggle {
			transition: 0.2s ease-in-out;
			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-out;
			}
			
			&:hover {
				.material-icons {
					color: var(--primary);
					transform: translateX(0.5rem);
				}
			}
		}
	}

	h3, .button .text {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	h3 {
		color: var(--grey);
		font-size: 0.875rem;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
	}

	.menu {
		margin: 0 -1rem;

		.button {
			display: flex;
			align-items: center;
			text-decoration: none;

			transition: 0.2s ease-in-out;
			padding: 0.5rem 1rem;

			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-in-out;
			}
			.text {
				color: var(--light);
				transition: 0.2s ease-in-out;
			}

			&:hover {
				background-color: var(--dark-alt);

				.material-icons, .text {
					color: var(--primary);
				}
			}

			&.router-link-exact-active {
				background-color: var(--dark-alt);
				border-right: 4px solid var(--primary);

				.material-icons, .text {
					color: var(--primary);
				}
			}
		}
	}

	.footer {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		p {
			font-size: 0.875rem;
			color: var(--grey);
		}
	}

	&.is-expanded {
		width: var(--sidebar-width);

		.menu-toggle-wrap {
			top: -3rem;
			
			.menu-toggle {
				transform: rotate(-180deg);
			}
		}

		h3, .button .text {
			opacity: 1;
		}

		.button {
			.material-icons {
				margin-right: 1rem;
			}
		}

		.footer {
			opacity: 0;
		}
	}

	@media (max-width: 1024px) {
		position: absolute;
		z-index: 99;
	}
}
</style>