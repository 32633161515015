<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Catégories d'articles</h2>
                <div v-if="isAdmin()"><button @click="this.$router.push({ path: '/maroc/admin/parametres/addcatarticle'})"  class="w-25 btn btn-primary">Ajouter une catégorie d'articles</button></div>                                
                <table class="table table-striped mt-3"></table>                
                <table class="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th scope="col">Code</th>
                            <th scope="col">Catégorie</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="( catarticle, index ) of catarticles" :key="index">
                            <td>{{ catarticle?.id }}</td>
                            <td>{{ catarticle?.categorie }}</td>    
                            <td v-if="isAdmin()">
                                <router-link  :to="`/maroc/admin/parametres/updatecatarticle/${catarticle.id}`" class="btn btn-outline-secondary me-2"> 
                                    <svg  width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                    </svg>
                                </router-link>
                                <button type="button" class="btn btn-outline-secondary" @click="deleteCatArticle(catarticle.id)" >
                                    <svg width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                    </svg>
                                </button>  
                            </td>                                                                                                             
                         </tr>
                    </tbody>
                </table>
            </div>
        <!-- </div>
    </div> -->
</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'CatArticles',
    data() {
        return {
            catarticles: [],
            filters: {
                id: null,
             
            },
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    },      
    mounted() {
        this.getCatarticles()
    },
    methods: {
        isAdmin() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN") return true
            }
        },     
        async getCatarticles() {
            try {
                const { data } = await this.$http.get('/catarticles');
                this.catarticles = data?.items;
            } catch (e) {
                console.error(e)
            }
        },
        async deleteCatArticle(catarticleid) {
            this.filters.id = catarticleid; 
            try {

              
                await this.$http.delete('/catarticles',
                {
                    params: this.filters
                }
                );
             
                this.$router.push({ path: '/maroc/admin/parametres/catarticles', replace: true })    
                this.$router.go();                           
             } catch (e) {
                console.error(e)
            }                              
        },

    }

}
</script>
<style lang="">

</style>