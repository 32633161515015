<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Codes postaux</h2>
                <table class="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Code postal</th>
                            <th scope="col">Ville</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="( macpo, index ) of macpos" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ macpo?.code }}</td>
                            <td>{{ macpo?.ville }}</td>                                                                                   
                         </tr>
                    </tbody>
                </table>
            </div>
        <!-- </div>
    </div> -->
</div>
</template>
<script>
export default {
    name: 'ListMacpos',
    data() {
        return {
            macpos: []
        }
    },
    mounted() {
        this.getMacpos()
    },
    methods: {
        async getMacpos() {
            try {
                const { data } = await this.$http.get('/macpos');
                this.macpos = data?.items;
            } catch (e) {
                console.error(e)
            }
        },
    }

}
</script>
<style lang="">

</style>