<template lang="">
    <!-- <PublicNav />  -->
    <router-view />
</template>
<script>
import PublicNav from '../PublicNav.vue';
export default {
    name: 'AuthLayout',
    components: {
        PublicNav
    },  
}
</script>
<style lang="">

</style>