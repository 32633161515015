<template lang="">
<div class="jumbotron m-1 container-fluid">

    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Modifier l'établissement {{this.client}} {{this.code_soc}} {{this.code}} </h2>
                <form @submit.prevent="updateEtab(this.client,this.code_soc,this.code)">
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="code_soc" class="d-flex align-items-left">Code société</label>
                            <select v-model="etam.code_soc" class="form-control">
                            <option v-for="clsoc in clsocs" :value="clsoc?.code">
                                 {{ clsoc?.code }} - {{ clsoc?.rsoc }} 
                            </option>
                            </select> 
                        </div>                    
                        <div class="col">
                            <label for="rsoc" class="d-flex align-items-left">Raison sociale</label>
                            <input type="text" id="rsoc" name="rsoc" class="form-control" v-model="etam.rsoc" maxlength="50" />    
                        </div>               
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Adresse</label>
                            <select v-model="etam.code_adr" class="form-control">
                            <option v-for="cladresse in cladresses" :value="cladresse?.code">
                                 {{ cladresse?.code }} - {{ cladresse?.adr_num }} {{ cladresse?.adr_rue }} {{ cladresse?.adr_cpos }} {{ cladresse?.adr_ville }}
                            </option>
                            </select> 
                        </div>                        
                         <!-- <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Code banque</label>
                            <input type="text" id="code_bnq" name="code_bnq" class="form-control" v-model="soc.code_bnq" maxlength="3" />    
                        </div> -->

                        <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Banque</label>
                            <select v-model="etam.code_bnq" class="form-control">
                            <option v-for="clbanque in clbanques" :value="clbanque?.code">
                                 {{ clbanque?.code }} - {{ clbanque?.banque }} Compte {{ clbanque?.code_compte }}
                            </option>
                            </select> 
                        </div>                          
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="cice" class="d-flex align-items-left">Code CIE</label>
                            <input type="text" id="cice" name="cice" class="form-control" v-model="etam.eta_cice" maxlength="15" />    
                        </div>                                         
                    </div>
                           
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/paie/parametres/cleta')" >Annuler</button>                                    

                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'AddAdresse',

    data() {
        return {
            cletas: [],        
            eta: [],   
            etam: {
                code_soc: null,
                rsoc: null,
                code_adr: null,
                code_bnq: null,
                eta_cice: null                     
            },
                            
            code: null,
            client: null,
      
            filters: {
                client: null,
                code_soc: null,
                code: null
            },                                   
            clbanques: [],
            cladresses: [],   
            clsocs: [],                       
            selected: null,         
        }              
    },
    async mounted() {
        this.code_soc = this.$route.params.code_soc;    
        this.code = this.$route.params.code;
        this.client = this.$route.params.client;
        try {
            this.filters.code = this.$route.params.code;   
            this.filters.code_soc = this.$route.params.code_soc;                          
            this.filters.client = this.$route.params.client;   
            const { data } = await this.$http.get('/cletas',
            {
                params: this.filters
            });
            this.eta = data?.items;
        } catch (e) {
            console.error(e)
        }

        this.etam.rsoc = this.eta[0].rsoc;
        this.etam.code_soc = this.eta[0].code_soc;        
        this.etam.code_adr = this.eta[0].code_adr;
        this.etam.code_bnq = this.eta[0].code_bnq;
        this.etam.eta_cice = this.eta[0].eta_cice;

        // Chargement des codes adresses
        this.getAddresses();
        this.getBanques();  
        this.getSocietes();          

    },

    // Chargement des codes adresses

 
    computed: {
        ...mapGetters(['currentUser'])
    },
   

    methods: {
        async updateEtab(client,code_soc,code) {
             try {

                this.filters.client = client; 
                this.filters.code_soc = code_soc;  
                this.filters.code = code;                  
                await this.$http.put('/cletas', this.etam, {params: this.filters});                
                this.$router.push('/paie/parametres/cleta')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },  
        async getAddresses() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client; 
                this.filters.code = '';                    
            const { data } = await this.$http.get('/cladresses',
            {
                params: this.filters
            });
            this.cladresses = data?.items;

        },     
        async getBanques() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client; 
                this.filters.code = '';                    
            const { data } = await this.$http.get('/clbanques',
            {
                params: this.filters
            });
            this.clbanques = data?.items;

        }, 
        async getSocietes() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client;   
            const { data } = await this.$http.get('/clsocs',
            {
                params: this.filters
            });
            this.clsocs = data?.items;

        },             

    }
}
</script>
