import { createWebHistory, createRouter } from 'vue-router';

// Nouvelle version ****************************************

import Accueil from '@/views/public/Accueil.vue';
import Contact from '@/views/public/Contact.vue';
import ParametresLayout from '@/components/maroc/ParametresLayout.vue';

// ********************* Paie
import AccueilPaie from '@/components/maroc/AccueilPaie.vue';
import ContactPaie from '@/components/maroc/ContactPaie.vue';

// ********************* Droit du travail et Paie
import DroitTravail from '@/views/paie/DroitTravail.vue';
import DroitContratsTravail from '@/views/paie/DroitContratsTravail.vue';
import DroitRemuneration from '@/views/paie/DroitRemuneration.vue';
import DroitHeures from '@/views/paie/DroitHeures.vue';
import DroitConges from '@/views/paie/DroitConges.vue';
import DroitSyndicats from '@/views/paie/DroitSyndicats.vue';
import DroitInspection from '@/views/paie/DroitInspection.vue';
import DroitTribunaux from '@/views/paie/DroitTribunaux.vue';
import DroitTravailInformel from '@/views/paie/DroitTravailInformel.vue';
// ********************* Paie
import PaieSalaire from '@/views/paie/PaieSalaire.vue';
import PaiePrimeAnciennete from '@/views/paie/PaiePrimeAnciennete.vue';
import PaiePrimesImposables from '@/views/paie/PaiePrimesImposables.vue';
import PaieCotisationsCnss from '@/views/paie/PaieCotisationsCnss.vue';
import PaieCotisationsRetraite from '@/views/paie/PaieCotisationsRetraite.vue';
import PaieImpotRevenu from '@/views/paie/PaieImpotRevenu.vue';
import PaieIndemnitesNonImposables from '@/views/paie/PaieIndemnitesNonImposables.vue';
// ********************** Déclarations
import Declarations from '@/views/paie/Declarations.vue';
import DeclarationCnss from '@/views/paie/DeclarationCnss.vue';


// *********** Fin nouvelle version

import Login from '../components/auth/UserLogin';
import Logout from '../components/auth/UserLogout.vue';
import Registration from '../components/auth/UserRegistration';
import EmailVerification from '../components/auth/EmailVerification';
import AuthLayout from '@/components/auth/AuthLayout'
import ListMacpos from '@/components/admin/ListMacpos.vue';
import ListMapays from '@/components/admin/ListMapays.vue';
import ListMalistes from '@/components/admin/ListMalistes.vue';
import ListAdusers from '@/components/admin/ListAdusers.vue';
import ListContacts from '@/components/admin/ListContacts.vue';
import UpdateUser from '@/components/admin/UpdateUser.vue';
import AddUser from '@/components/admin/AddUser.vue';
import UpdateContact from '@/components/admin/UpdateContact.vue';


// Gestion des adresses
import ListCladresse from '@/components/maroc/ListCladresse.vue';
import AddAdresse from '@/components/maroc/AddAdresse.vue';
import UpdateAdresse from '@/components/maroc/UpdateAdresse.vue';

// Gestion des banques
import ListClbanque from '@/components/maroc/ListClbanque.vue';
import AddBanque from '@/components/maroc/AddBanque.vue';
import UpdateBanque from '@/components/maroc/UpdateBanque.vue';

// Gestion des sociétés
import ListClsoc from '@/components/maroc/ListClsoc.vue';
import AddSociete from '@/components/maroc/AddSociete.vue';
import UpdateSociete from '@/components/maroc/UpdateSociete.vue';

// Gestion des établissements
import ListCleta from '@/components/maroc/ListCleta.vue';
import AddEtab from '@/components/maroc/AddEtab.vue';
import UpdateEtab from '@/components/maroc/UpdateEtab.vue';

// Gestion des articles
import Articles from '@/components/admin/Articles.vue';
import AddArticle from '@/components/admin/AddArticle.vue';
import UpdateArticle from '@/components/admin/UpdateArticle.vue';


// Gestion des catégories articles
import CatArticles from '@/components/admin/CatArticles.vue';
import AddCatArticle from '@/components/admin/AddCatArticle.vue';
import UpdateCatArticle from '@/components/admin/UpdateCatArticle.vue';





const routes = [
    { path: '/', redirect: '/maroc' },
    { path: '/maroc', name: 'Accueil', component: Accueil },
    { path: '/maroc/contact', name: 'Contact', component: Contact, meta: { title: 'Nous contacter' } }, 

    { path: '/maroc/paie',    name: 'AccueilPaie',    component: AccueilPaie },    
    { path: '/maroc/paie/contact', name: 'ContactPaie', component: ContactPaie }, 
    // { path: '/paie/parametres/clsoc', name: 'ListClsoc', component: ListClsoc },       
    { path: '/maroc/paie/parametres',
        component: ParametresLayout,
        children: [
            // Structures Juridique - Gestion des sociétés
            { name: 'ListClsoc',  path: 'clsoc', component: ListClsoc },
            { name: 'AddSociete',  path: 'addsociete', component: AddSociete },  
            { name: 'UpdateSociete',  path: 'updatesociete/:client/:code', component: UpdateSociete }, 

            // Structure juridique -  Gestion des établissements           
            { name: 'ListCleta',  path: 'cleta', component: ListCleta },  
            { name: 'AddEtab',  path: 'addetab', component: AddEtab },
            { name: 'UpdateEtab',  path: 'updateeta/:client/:code_soc/:code', component: UpdateEtab },              

            // Gestion des adresses 
            { name: 'ListCladresse',  path: 'cladresse', component: ListCladresse },                              
            { name: 'AddAdresse',  path: 'addadresse', component: AddAdresse },  
            { name: 'UpdateAdresse',  path: 'updateadresse/:client/:code', component: UpdateAdresse }, 

            // Gestion des banques
            { name: 'ListClbanque',  path: 'clbanque', component: ListClbanque }, 
            { name: 'AddBanque',  path: 'addbanque', component: AddBanque },   
            { name: 'UpdateBanque',  path: 'updatebanque/:client/:code', component: UpdateBanque },  



        ]
    
    },
    { path: '/maroc/admin/parametres',
            component: ParametresLayout,
        children: [
            { name: 'Articles',  path: 'articles', component: Articles },
            { name: 'AddArticle',  path: 'addarticle', component: AddArticle },  
            { name: 'UpdateArticle',  path: 'updatearticle/:id', component: UpdateArticle },
            
            { name: 'CatArticles',  path: 'catarticles', component: CatArticles },
            { name: 'AddCatArticle',  path: 'addcatarticle', component: AddCatArticle },               
            { name: 'UpdateCatArticle',  path: 'updatecatarticle/:id', component: UpdateCatArticle },  
                                     
            { name: 'ListMacpos', path: 'macpos', component: ListMacpos },
            { name: 'ListMapays', path: 'mapays', component: ListMapays },
            { name: 'ListMalistes', path: 'malistes', component: ListMalistes },

            { name: 'ListAdusers',  path: 'adusers', component: ListAdusers },
            { name: 'UpdateUser',  path: 'updateuser/:id', component: UpdateUser },
            { name: 'AddUser',  path: 'adduser', component: AddUser }, 
            { name: 'ListContacts',  path: 'contacts', component: ListContacts },
            { name: 'UpdateContact',  path: 'updatecontact/:id', component: UpdateContact },            
        ]
    },
    {
        path: '/maroc/auth',
        component: AuthLayout,
        children: [

            {
                name: 'Registration',
                path: 'registration',
                component: Registration,
                meta: { title: 'Inscription - La paie au Maroc' }                
            },
            {
                name: 'Login',
                path: 'login',
                component: Login,
                meta: { title: 'Se connecter - La paie au Maroc' }

            },
            {
                name: 'Logout',
                path: 'logout',
                component: Logout,
            },            
            {
                name: 'VerifyEmail',
                path: 'verify-email',
                component: EmailVerification,
                meta: { title: 'Vérification de mail - La paie au Maroc' }                
            }
             
 
        ]
    },
    {
        path: '/maroc/droit',
        children: [

            {
                name: 'DroitTravail',
                path: 'travail',
                component: DroitTravail,
                meta: { title: 'Le droit du travail au Maroc' }
            },
            {
                name: 'DroitContratsTravail',
                path: 'contrat-travail',
                component: DroitContratsTravail,
                meta: { title: 'Les contrats de travail au Maroc' }
            },    
            {
                name: 'DroitRemuneration',
                path: 'remuneration',
                component: DroitRemuneration,
                meta: { title: 'La rémunération au Maroc' }               
            },  
            {
                name: 'DroitHeures',
                path: 'heures-de-travail',
                component: DroitHeures,
                 meta: { title: 'Les heures de travail au Maroc' }                 
            },                                   
            {
                name: 'DroitConges',
                path: 'conges',
                component: DroitConges,
                meta: { title: 'Les congés au Maroc' }  
            },  
            {
                name: 'DroitSyndicats',
                path: 'syndicats',
                component: DroitSyndicats,
                meta: { title: 'Les syndicats au Maroc' }                  
            },  
            {
                name: 'DroitInspection',
                path: 'inspection',
                component: DroitInspection,
                meta: { title: `L'inspection du travail au Maroc` }                  
            }, 
            {
                name: 'DroitTribunaux',
                path: 'tribunaux-du-travail',
                component: DroitTribunaux,
                meta: { title: 'Les tribunaux du travail au Maroc' }                  
            }, 
            {
                name: 'DroitTravailInformel',
                path: 'travail-informel',
                component: DroitTravailInformel,
                meta: { title: 'Le travail informel au Maroc' }                  
            },   
                                                      
        ]
    },
    {
        path: '/maroc/paie',
        children: [   
            { name: 'PaieSalaire', path: 'salaire', component: PaieSalaire, meta: { title: 'Les salaires au Maroc' }},  
            { name: 'PaiePrimeAnciennete', path: 'prime-anciennete', component: PaiePrimeAnciennete, meta: { title: `La prime d'ancienneté au Maroc` } },  
            { name: 'PaiePrimesImposables', path: 'primes-imposables', component: PaiePrimesImposables, meta: { title: 'Les primes imposables au Maroc' } },      
            { name: 'PaieCotisationsCnss', path: 'cotisations-cnss', component: PaieCotisationsCnss, meta: { title: 'Les cotisations CNSS au Maroc' } },    
            { name: 'PaieCotisationsRetraite', path: 'cotisations-retraite', component: PaieCotisationsRetraite, meta: { title: 'Les cotisations de retraite au Maroc' } },
            { name: 'PaieImpotRevenu', path: 'impot-revenu', component: PaieImpotRevenu,meta: { title: `L'impôt sur le revenu au Maroc` } },    
            { name: 'PaieIndemnitesNonImposables', path: 'indemnites-non-imposables', component: PaieIndemnitesNonImposables, meta: { title: 'Les indemnités non imposables au Maroc au Maroc' } },                                                                                                                                     
        ]
    },
   {
        path: '/maroc/declarations',
        children: [   
            { name: 'Declarations', path: '', component: Declarations, meta: { title: 'Le différentes déclarations de la paie au Maroc' } },  
            { name: 'DeclarationCnss', path: 'cnss', component: DeclarationCnss, meta: { title: 'La déclarations CNSS au Maroc' } },                                                                                                                                       
        ]
    },    

    

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;