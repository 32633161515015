<template lang="">
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Ajouter un établissement</h2>
                <form @submit.prevent="addEtablissement()">
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="code_soc" class="d-flex align-items-left">Code société</label>
                            <select v-model="eta.code_soc" class="form-control">
                            <option v-for="clsoc in clsocs" :value="clsoc?.code">
                                 {{ clsoc?.code }} - {{ clsoc?.rsoc }} 
                            </option>
                            </select> 
                        </div>                    
                        <div class="col">
                            <label for="rsoc" class="d-flex align-items-left">Raison sociale</label>
                            <input type="text" id="rsoc" name="rsoc" class="form-control" v-model="eta.rsoc" maxlength="50" />    
                        </div>               
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="code_adr" class="d-flex align-items-left">Adresse</label>
                            <select v-model="eta.code_adr" class="form-control">
                            <option v-for="cladresse in cladresses" :value="cladresse?.code">
                                 {{ cladresse?.code }} - {{ cladresse?.adr_num }} {{ cladresse?.adr_rue }} {{ cladresse?.adr_cpos }} {{ cladresse?.adr_ville }}
                            </option>
                            </select> 
                        </div>                        
                         <!-- <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Code banque</label>
                            <input type="text" id="code_bnq" name="code_bnq" class="form-control" v-model="soc.code_bnq" maxlength="3" />    
                        </div> -->

                        <div class="col">
                            <label for="code_bnq" class="d-flex align-items-left">Banque</label>
                            <select v-model="eta.code_bnq" class="form-control">
                            <option v-for="clbanque in clbanques" :value="clbanque?.code">
                                 {{ clbanque?.code }} - {{ clbanque?.banque }} Compte {{ clbanque?.code_compte }}
                            </option>
                            </select> 
                        </div>                          
                    </div>
                    <div class="row mt-3" >
                        <div class="col">
                            <label for="cice" class="d-flex align-items-left">Code CIE</label>
                            <input type="text" id="cice" name="cice" class="form-control" v-model="eta.eta_cice" maxlength="15" />    
                        </div>                                         
                    </div>
                           
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/paie/parametres/cleta')" >Annuler</button>                                    

                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    name: 'AddEtab',
    data() {
        return {
            eta: {
                code_soc: null,
                rsoc: null,
                code_adr: null,
                code_bnq: null,
                eta_cice: null         
                     
            },
            total: null,
            filters: {
                client: null,
                code: null                
            },  
            clbanques: [],
            cladresses: [],   
            clsocs: [],                       
            selected: null,                      
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    },  

    // Chargement des codes adresses
    mounted() {
       this.getAdresses();    
       this.getBanques();
       this.getSocietes();   
    },    

    methods: {
        async addEtablissement() {

            try {
                this.filters.client = this.currentUser.client;  
                this.filters.code_soc = this.eta.code_soc;   
                     const { data } = await this.$http.get('/cletas',
                {
                     params: this.filters
                });
                this.cletas = data?.items;
                var btCodenum = "000"+( Number(this.cletas.length) +1).toString();
                this.eta.client = this.currentUser.client;    
                this.eta.code = btCodenum.substr(btCodenum.length-3);

            } catch (e) {
                console.error(e)
            }
            try {
                await this.$http.post('/cletas', this.eta);
                this.$router.push('/paie/parametres/cleta')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },

        async getAdresses() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client;   
            const { data } = await this.$http.get('/cladresses',
            {
                params: this.filters
            });
            this.cladresses = data?.items;

        },

        async getBanques() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client;   
            const { data } = await this.$http.get('/clbanques',
            {
                params: this.filters
            });
            this.clbanques = data?.items;

        },    

        async getSocietes() {
            if (this.currentUser.client != 'A000')
                this.filters.client = this.currentUser.client;   
            const { data } = await this.$http.get('/clsocs',
            {
                params: this.filters
            });
            this.clsocs = data?.items;

        },              
                
    }
}
</script>
<style lang="">

</style>