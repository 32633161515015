<template>
<div>
    <div class="col m-2">
        <div class="card m-2">
            <div class="card-body">
                <h6 class="card-title"><b>Droit du Travail</b></h6>
                <router-link class="dropdown-item" to='/maroc/droit/travail'>Généralités</router-link>
				<router-link class="dropdown-item" to='/maroc/droit/contrat-travail'>Contrats de travail</router-link>           
				<router-link class="dropdown-item" to='/maroc/droit/remuneration'>Rémunération</router-link>     
				<router-link class="dropdown-item" to='/maroc/droit/heures-de-travail'>Heures de travail</router-link> 		
				<router-link class="dropdown-item" to='/maroc/droit/conges'>Congés</router-link> 	
				<router-link class="dropdown-item" to='/maroc/droit/syndicats'>Syndicats</router-link> 	
				<router-link class="dropdown-item" to='/maroc/droit/inspection'>Inspection du travail</router-link> 
				<router-link class="dropdown-item" to='/maroc/droit/tribunaux-du-travail'>Tribunaux du travail</router-link> 	
				<router-link class="dropdown-item" to='/maroc/droit/travail-informel'>Travail informel</router-link>                                                           
            </div>
        </div>
        <div class="card m-2">        
            <div class="card-body">
                <h6 class="card-title"><b>La paie</b></h6>
				<router-link class="dropdown-item" to='/maroc/paie/salaire'>Salaire de base</router-link>  
				<router-link class="dropdown-item" to='/maroc/paie/prime-anciennete'>Prime d'ancienneté</router-link> 				 
				<router-link class="dropdown-item" to='/maroc/paie/primes-imposables'>Primes imposables</router-link>     
				<router-link class="dropdown-item" to='/maroc/paie/cotisations-cnss'>Cotisations CNSS</router-link>     
				<router-link class="dropdown-item" to='/maroc/paie/cotisations-retraite'>Cotisations Retraite</router-link>      				
				<router-link class="dropdown-item" to='/maroc/paie/impot-revenu'>Impôt sur le revenu</router-link>   
				<router-link class="dropdown-item" to='/maroc/paie/indemnites-non-imposables'>Indemnités non imposables</router-link>     				               
   
            </div>
        </div>
        <div class="card m-2">        
            <div class="card-body">
                <h6 class="card-title"><b>Déclarations</b></h6>
				<router-link class="dropdown-item" to='/maroc/declarations'>Les différentes déclaration</router-link>  
				<router-link class="dropdown-item" to='/maroc/declarations/cnss'>Déclaration CNSS</router-link>   
				<router-link class="dropdown-item" to='/maroc'>Déclaration impôt sur le revenu (IR)</router-link> 
				<router-link class="dropdown-item" to='/maroc'>Déclaration Annuelle des revenus (DAR)</router-link>  	
				<router-link class="dropdown-item" to='/maroc'>Déclaration Cotisations Retraite (CMR)</router-link>                  
            </div>
        </div>        
    </div>
</div>

</template>

<script>
export default {
    name: 'BarreDroite'
}
</script>

