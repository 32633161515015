<template lang="">
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Ajouter une catégorie d'articles </h2>
                <form @submit.prevent="addCatArticle()">
                    <div class="row mt-2" >
                        <div class="col">
                        <label for="categorie" class="d-flex align-items-left">Catégorie</label>
                        <input type="text" id="categorie" name="categorie" class="form-control" v-model="catarticle.categorie" />    
                        </div>                        
                    </div>                    
                    <button type="submit" class="btn btn-primary mt-2 mb-2">Enregistrer</button>
                    <button class="btn btn-primary ms-2" @click="this.$router.push('/maroc/admin/parametres/catarticles')" >Annuler</button>                                    
                </form>
            </div>
        <!-- </div>
    </div> -->
</div> 
</template>
<script>
import Swal from 'sweetalert2';

export default {
    name: 'AddArticle',

    data() {
        return {
            catarticle: {
                categorie: null,

            }

        }              
    },

    methods: {
        async addCatArticle() {

            try {
                await this.$http.post('/catarticles', this.catarticle);
                this.$router.push('/admin/parametres/catarticles')
            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e.response.data.message,
                });
            }
        },
     

    }
}
</script>
<style lang="">

</style>