<template>
</template>

<script>
export default {
    name: 'UserLogout',
    created() {
        this.$store.commit('LOGOUT');
        // this.$router.replace('/auth/login')
        this.$router.push('/maroc')
  }
}
</script>