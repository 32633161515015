<template>
<div class="jumbotron m-1 container-fluid">
    <!-- <div class="row mt-5">
        <div class="col-md-10 m-auto"> -->
            <div class="card card-body text-center">
                <h2>Listes</h2>
                <table class="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Listes</th>
                            <th scope="col">Code</th>
                            <th scope="col">Libellé</th>                                                  
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="( maliste, index ) of malistes" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ maliste?.liste }}</td>
                            <td>{{ maliste?.code }}</td>      
                            <td>{{ maliste?.libelle }}</td>                                                                                                         
                         </tr>
                    </tbody>
                </table>
            </div>
        <!-- </div>
    </div> -->
</div>
</template>
<script>
export default {
    name: 'ListMalistes',
    data() {
        return {
            malistes: []
        }
    },
    mounted() {
        this.getMalistes()
    },
    methods: {
        async getMalistes() {
            try {
                const { data } = await this.$http.get('/malistes');
                this.malistes = data?.items;
            } catch (e) {
                console.error(e)
            }
        },
    }

}
</script>
<style lang="">

</style>