<template>

<div class="jumbotron m-1 container-fluid">
  <h1>Contact Paie</h1>
  <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
  <hr class="my-4">
  <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  <p class="lead">
    <a class="btn btn-primary btn-lg" href="#" role="button">Learn more .....</a>
  </p>
</div>



</template>

<script>
export default {
    name: 'ContactPaie'
}
</script>